<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import type { TypedSchema } from 'vee-validate'
import * as zod from 'zod'
import { useCountriesStore } from '~/stores/countries'
import { FetchError } from 'ofetch'

const localePath = useLocalePath()
const { getStaticPageSlug } = useStaticPages()

const name = 'subscription-section'
const countriesStore = useCountriesStore()
await countriesStore.loadCountries()
await countriesStore.loadRegions()

const { alert } = useAlert()
const { t } = useI18n()

const tocLink = computed(() => {
  return localePath({
    name: 'slug',
    params: {
      slug: getStaticPageSlug(staticPageKeys.PRIVACY_POLICY),
    },
  })
})

const { getCaptchaToken } = useVueRecaptcha()

const signupValid = ref(false)
const submit = ref(0)

const { requiredString, optionalString } = useRules()
const validationSchema: ComputedRef<TypedSchema> = computed((): TypedSchema => {
  return toTypedSchema(
    zod.object({
      email: requiredString().email({
        message: t('base.form.emailNotValid'),
      }),
      country: requiredString(),
      region: optionalString(),
    })
  )
})

const { validate, values, errors, meta, resetForm, setFieldError } = useForm({
  validationSchema,
})

const { value: email } = useField<string>('email')
const { value: country } = useField<string>('country')
const { value: region } = useField<string | undefined>('region')
const signupAsWell = ref(false)

const isCountryDividedToRegions: ComputedRef<boolean> = computed(
  (): boolean => {
    const countriesWithRegions = Object.keys(countriesStore.regions)
    const include = countriesWithRegions.includes(country.value as string)
    if (!include) {
      if (values.region !== undefined) {
        region.value = undefined
      }
    } else {
      region.value = ''
    }
    return include
  }
)

function checkRegionValue(): boolean {
  const result = requiredString().safeParse(region.value)
  if (!result.success) {
    setFieldError('region', result.error.formErrors.formErrors[0])
    return false
  }
  return true
}

const valid: ComputedRef<boolean> = computed((): boolean => {
  if (!meta.value.valid) {
    return false
  }

  if (isCountryDividedToRegions.value && !checkRegionValue()) return false
  return !(signupAsWell.value && !signupValid.value)
})

const toast = useToast()

async function onSubmit() {
  const valid = await validate()
  if (!valid) {
    return
  }

  if (isCountryDividedToRegions.value && !checkRegionValue()) return

  // TODO: What does this do?
  if (signupAsWell.value) {
    // submit signup form
    submit.value++
  } else {
    const recaptchaToken = await getCaptchaToken('subscribe')

    if (!recaptchaToken) {
      return
    }

    try {
      await createNewsSubscribersRepository().subscribe({
        email: values.email,
        countryId: values.country,
        regionId: values.region,
        recaptchaToken,
      })
      resetForm()
      alert(['base.subscription.success'])
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.statusCode === 409) {
          toast.add({
            title: t('base.subscription.subscriptionExist'),
            color: 'red',
          })
        }
      } else {
        toast.add({
          title: t('base.subscription.error'),
          color: 'red',
        })
      }
    }
  }
}
</script>

<template>
  <section class="mx-auto py-16">
    <div class="mx-auto !max-w-4xl px-4">
      <h2 class="mb-4 text-3xl font-bold">
        {{ $t('base.subscription.title') }}
      </h2>
      <div class="mb-8 text-lg sm:mb-16">
        {{ $t('base.subscription.description') }}
      </div>
      <form class="mx-6 my-8 sm:mx-12" @submit.prevent="onSubmit">
        <div class="mx-0 flex flex-wrap">
          <div class="w-full lg:w-1/2">
            <FormFieldInput
              v-model="email"
              name="email"
              :parent-name="name"
              property-name="subscription-email"
              label="signup.email"
              :icon="'envelope'"
              required
              :error="errors.email"
            />
          </div>
          <div class="w-full lg:w-1/2">
            <div>
              <FormFieldInput
                v-model="country"
                name="country"
                :parent-name="name"
                property-name="subscription-country"
                type="select"
                label="signup.country"
                :icon="'globe'"
                :items="countriesStore.countries"
                :items-loading="
                  countriesStore.countriesLoading ||
                  countriesStore.regionsLoading
                "
                required
                :error="errors.country"
              />
            </div>
          </div>
          <div class="w-full lg:w-1/2">
            <Transition
              enter-active-class="transition-all duration-500 ease-out"
              leave-active-class="transition-all duration-500 ease-in"
              enter-from-class="translate-y-[-20px] opacity-0"
              leave-to-class="translate-y-[-20px] opacity-0"
              appear
            >
              <FormFieldInput
                v-show="isCountryDividedToRegions"
                v-model="region"
                name="region"
                :parent-name="name"
                property-name="region"
                type="select"
                label="signup.region"
                :icon="'flag'"
                :items="countriesStore.regions[country]"
                :items-loading="countriesStore.regionsLoading"
                :error="errors.region"
                required
              />
            </Transition>
          </div>
        </div>
        <FormFieldInput
          v-model="signupAsWell"
          name="signupAsWell"
          :parent-name="name"
          property-name="signup-as-well"
          type="checkbox"
          label="base.subscription.signupAsWell"
        />
        <Transition
          enter-active-class="transition-all duration-500 ease-out"
          leave-active-class="transition-all duration-500 ease-in"
          enter-from-class="translate-y-[-20px] opacity-0"
          leave-to-class="translate-y-[-20px] opacity-0"
          appear
        >
          <NewsSignupForm
            v-if="signupAsWell"
            :toc-link="tocLink"
            inline
            :email="email"
            :country="country"
            :region="region"
            :submit="submit"
            @valid="(val) => (signupValid = val)"
            @reset-form="resetForm()"
            :show-captcha-text="false"
          />
        </Transition>
        <button
          class="border-2 border-black px-8 py-4 text-xl font-bold disabled:border-neutral-400 disabled:text-neutral-400"
          type="submit"
          :disabled="!valid"
        >
          {{ $t('base.subscription.submit') }}
        </button>
      </form>
      <div class="text-sm">
        {{ $t('base.subscription.emailSafe') }}
        <a :href="tocLink" target="_blank">
          {{ $t('base.subscription.terms') }}
        </a>
        .
      </div>
      <UiRecaptchaText />
    </div>
  </section>
</template>
