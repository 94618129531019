import type { NewsSubscriber, NewsSubscribeRequest } from '~/types/api'

export function createNewsSubscribersRepository() {
  const { $api } = useNuxtApp()
  const { get, post } = baseRepository($api)

  const DOMAIN = 'news-subscribes'

  async function subscribe(body: NewsSubscribeRequest) {
    return post<undefined>([DOMAIN], { body })
  }

  async function getByEmail(email: string) {
    return get<NewsSubscriber>([DOMAIN, email])
  }

  return {
    subscribe,
    getByEmail,
  }
}
